<script>
import { required } from 'vuelidate/lib/validators'

import { authMethods, authFackMethods, notificationMethods } from '@/state/helpers'
import service from '../../../server/http_service'

// import Layout from '../../layouts/main'
// import PageHeader from '@/components/page-header'
// import service from '../../../server/http_service'
import global_func from '@/global_function'
import i18n from '@/i18n'
// import moment from 'moment'
export default {
  page: {
    title: 'Auth',
    meta: [{ name: 'description' }],
  },
  components: { },
  data() {
    var lang = i18n.t('login_page')
    return {
      lang: lang,
      user_name: '',
      password: '',
      placeholder1: lang['placeholder_username'],
      placeholder2: lang['placeholder_password'],
      submitted: false,
      showDismissibleAlert: false,
      block_ui_flg: true,
      // register for dtc user
      user_name_display: '', // สำหรับแสดงบนหน้าจอ
      user_name_regiser: '', // สำหรับลงทะเบียน
      password_register: '', // สำหรับลงทะเบียน
      user_name_auto: '', // สำหรับเขา้สู่ระบบอัตโนมัติ
      password_auto: '', // สำหรับเขา้สู่ระบบอัตโนมัติ
      owner_id : '',
      code_ref: '',
      email_user: '',
      email_code_input: '',
      minutesSpan: '00',
      secondsSpan: '00',
      placeholder4: lang['placeholder_username2'],
      placeholder5: lang['placeholder_password2'],
      placeholder3: 'ยืนยันรหัสผ่าน',
      placeholder_email: 'ระบุอีเมล',
      placeholder_code: 'ระบุรหัส',
      user_name_vaild: false,
      pass_vaild: false,
      pass_vaild2: false,
      user_name_vaild_text: '',
      pass_vaild_text: '',
      showDismissibleAlert2: false,
      email_register_flg: 0,
      email_register_vaild: false,
      email_code_vaild: false,
      email_code_status: 0,
    }
  },
  created() {
    // document.body.classList.add('auth-body-bg')
    localStorage.setItem('auth_user_data', '')
  },
  mounted() {
    this.checkAuthSingleSignOn()
  },
  validations: {
    user_name: { required },
    password: { required },
  },
  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    checkUser2() {
      this.showDismissibleAlert = false
      // console.log('check user from system')
      if (localStorage.getItem('auth_user_data') === null || localStorage.getItem('auth_user_data').length === 0) {
        let data_obj = {
          user_token : '',
          oauth_provider : 'system',
        }
        localStorage.setItem('auth_user_data', data_obj)
      }
      // let auth_data = localStorage.getItem('auth_user_data')
      const { user_name_auto, password_auto } = this
      if (user_name_auto) {
        service.postService('check_user_platform', { 
          user_name : user_name_auto, 
          owner_id : this.owner_id, 
          
        }).then((rp) => {
          // console.log(rp)
          if (rp.status == 'ok') {
            localStorage.setItem('users', JSON.stringify(rp.data))
            this.login({ user_name_auto, password_auto })
            if (localStorage.getItem('province') === null || localStorage.getItem('province').length === 0) {
              localStorage.setItem('province', JSON.stringify(rp.province))
            }
              
            if (localStorage.getItem('trucktype') === null || localStorage.getItem('trucktype').length === 0) {
              localStorage.setItem('trucktype', JSON.stringify(rp.trucktype))
            }

          } else {
            this.showDismissibleAlert = true
          }
          global_func.CloseMsg()
        })
      }
    },
    checkUserDuplicate() {
      this.showDismissibleAlert2 = false
      this.submitted = true
      
      // stop here if form is invalid
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      } else {
        if (localStorage.getItem('auth_user_data') === null || localStorage.getItem('auth_user_data').length === 0) {
          let data_obj = {
            user_token : '',
            oauth_provider : 'system',
          }
          localStorage.setItem('auth_user_data', data_obj)
        }
        let auth_data = localStorage.getItem('auth_user_data')
        const { user_name, password, email_user, code_ref } = this
        if (user_name && password) {
          service
            .postService('checkUserDuplicate', {
              user_name: user_name,
              auth_data: auth_data,
            })
            .then(rp => {
              // console.log(rp)
              if(rp.status == 'ok') {
                service.postService('register_user', { user_name, password, email_user, code_ref, auth_data }).then((rp) => {
                  // console.log(rp)
                  if (rp.status == 'ok') {
                    localStorage.setItem('users', JSON.stringify(rp.data))
                    this.login({ user_name, password })
                    if (localStorage.getItem('province') === null || localStorage.getItem('province').length === 0) {
                      localStorage.setItem('province', JSON.stringify(rp.province))
                    }
                    if (localStorage.getItem('trucktype') === null || localStorage.getItem('trucktype').length === 0) {
                      localStorage.setItem('trucktype', JSON.stringify(rp.trucktype))
                    }
                  }
                })
              } else {
                this.showDismissibleAlert2 = true
              }
            })
        }
      }
    },
    checkAuthSingleSignOn() {
      let href = location.href
      // console.log(href)
      let href_sp = href.split('bh_auth=')
      let user_token = ''
      let that = this
      
      if(typeof href_sp[1] != 'undefined') {
        global_func.LoadMsg('กำลังโหลดข้อมูล','','')
        user_token = href_sp[1]
        // console.log(user_token)
        localStorage.clear()
        service
          .postService('checkAuthSingleSignOn', {
            user_token: user_token,
          })
          .then(rp => {
            // console.log(rp)
            if(rp.status == 'success') {
              this.user_name_auto = rp.user_data.user_name
              this.owner_id = rp.user_data.owner_id
              localStorage.setItem('logout_redirect_path', rp.login_from)
              setTimeout(() => {
                that.checkUser2()
              }, 1500)
            } else if(rp.status == 'not_user_data'){
              let user_data = JSON.stringify(rp.user_data)
              localStorage.setItem('auth_user_data', user_data)
              this.auto_register()
              // this.user_name_display = rp.user_data.name_login
              // global_func.CloseMsg()
              // this.$bvModal.show('modal_msg_new_user')
            } else if(rp.status == 'not_found') {
              // global_func.CloseMsg()
              if (confirm('Token time out') == true) {
                window.location.href = 'http://platform.dtc.co.th/login'
              } else {
                window.location.href = 'http://platform.dtc.co.th/login'
              }
              // alert('Token time out')
            } else {
              // global_func.CloseMsg()
              window.location.href = '/'
            }
          })
      }
    },
    getTimeRemaining(endtime) {
      const total = Date.parse(endtime) - Date.parse(new Date())
      const seconds = Math.floor((total / 1000) % 60)
      const minutes = Math.floor((total / 1000 / 60) % 60)
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24)
      const days = Math.floor(total / (1000 * 60 * 60 * 24))
  
      return {
        total,
        days,
        hours,
        minutes,
        seconds,
      }
    },
    initializeClock(endtime) {
      var that = this
      function updateClock() {
        const t = that.getTimeRemaining(endtime)

        that.minutesSpan = ('0' + t.minutes).slice(-2)
        that.secondsSpan = ('0' + t.seconds).slice(-2)

        if (t.total <= 0) {
          clearInterval(timeinterval)
        }
      }

      updateClock()
      const timeinterval = setInterval(updateClock, 1000)
    },
    checkStr (val) {
      var str = val.match(/(.*[!,@,#,$,%,^,&,*,?,_,~].*[!,@,#,$,%,^,&,*,?,_,~])/) //ตัวอักษรที่ไม่ต้องการให้มี
      if (val.indexOf('\'')!= -1) return true //เครื่องหมาย '
      if (val.indexOf('"')!= -1) return true //เครื่องหมาย "
      for (let i = 0; i < str.length; i++) {
        if (val.indexOf(str.charAt(i))!= -1) return true
      }
      return false
    },
    auto_register() {
      let auth_data = localStorage.getItem('auth_user_data')
      let auth_data_obj = JSON.parse(auth_data)
      // console.log(auth_data_obj)
      service
        .postService('autoRegisterUser', {
          register_data: auth_data_obj,
        })
        .then(rp => {
          // console.log(rp)
          if(rp.status == 'success') {
            let that = this
            this.user_name_auto = rp.data.user_name
            this.owner_id = rp.data.owner_id
            setTimeout(() => {
              that.checkUser2()
            }, 1500)
          }
        })
    },
  },
}
</script>

<template>
  <div />
</template>
